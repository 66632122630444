<template>
  <div id="adminlogin">
    <div class="box">
      <div class="boxsty login">
        <div class="title">
          <div class="text" :class="{ active: logintype == 1 }" @click="changelogin(1)">密码登录</div>
          <div class="text" :class="{ active: logintype == 2 }" @click="changelogin(2)">验证码登录</div>
        </div>
        <div class="inputbox inputpad">
          <div class="label">中国 +86</div>
          <el-input class="input inputlabel" type="text" v-model="phone" placeholder="请输入手机号" />
        </div>
        <div class="inputbox" v-if="logintype == 1">
          <el-input class="input" type="password" v-model="password" placeholder="输入登录密码" @keyup.enter.native="lgoin"
            show-password />
        </div>
        <div class="inputbox" v-if="logintype == 2">
          <el-input class="input" type="text" v-model="code" autocomplete="new-password" @keyup.enter.native="lgoin"
            placeholder="输入验证码" />
          <div class="codebox" v-if="!codeshow" @click="getcode">获取验证码</div>
          <div class="codebox" style="color:#3773da;" v-else>{{ codetime }}秒后获取</div>
        </div>
        <div class="loginbtn" @click="lgoin">登录</div>
      </div>
      <div class="boxsty exp">
        <div class="title">线上客合作伙伴</div>
        <div class="text">用有用的技术 开发出有用的产品</div>
        <div class="link"><a href="https://www.xcooline.com" target="_blank">查看更多案例</a></div>
        <div class="imgbox">
          <div class="img"><img src="../../assets/login/全球鹰演艺.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/嘉仕达.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/天久律联.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/宣品严选.png" alt="" /></div>
          <div class="img"><img src="../../assets/login/欧瑞朗.png" alt="" /></div>
          <div class="img"><img src="../../assets/login/汉玺牛肉.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/润居房.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/舒冠母婴.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/若比邻超市.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/诚利达.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/鲜美意.png" alt="" /></div>
          <div class="img"><img class="" src="../../assets/login/麦铂精酿.png" alt="" /></div>
        </div>
      </div>
    </div>
    <div class="copyright">蜀ICP备19013746号</div>
  </div>
</template>

<script>
  import {
    adminroutes
  } from '../../router/admin.js'
  import {
    eltips
  } from "../../util/util";
  import {
    resetRouter
  } from '@/router/index.js'
  import store from '@/store/index.js'
  import router from '@/router/index.js'
  import {
    changeroute
  } from '@/router/routerPermission.js'
  export default {
    name: 'adminlogin',
    data() {
      return {
        phone: '',
        password: '',
        code: '',
        logintype: 1,
        codeshow: false,
        codetime: 59,
      };
    },
    created() {
      console.log(this.$router.options.routes, '----------$route')
    },
    methods: {
      handlogin(e) {
        console.log(e)
        this.lgoin()
      },
      //切换tab
      changelogin(type) {
        this.logintype = type;
      },
      //获取验证码
      getcode() {
        this.axios.post("/api/send_code", {
          phone: this.phone
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.codeshow = true;
            let autocode = setInterval(() => {
              if (this.codetime > 1) {
                this.codetime--;
              } else {
                this.codeshow = false;
                this.codetime = 59;
                clearInterval(autocode);
              }
            }, 1000);
          } else {
            eltips(res.msg, 'error')
          }
        }).catch((error) => {
          console.log(error);
        })
      },
      lgoin() {
        let data = {
          phone: this.phone,
          type: this.logintype,
          guard_name: 'api',
          is_customer: 0,
        }

        console.log(data, 'data')

        if (this.logintype == 1) {
          data.password = this.password
        } else {
          data.sms_code = this.code
        }
        sessionStorage.setItem('guardName', 'api')
        this.axios.post("/api/login", data).then((res) => {
          console.log(res);
          if (res.code == 200) {
            eltips('登录成功', 'success')
            sessionStorage.setItem('token', res.data.access_token)
            this.xinxi()
            this.getConfig()
          } else {
            eltips(res.msg, 'error')
          }
        })
      },
      //获取个人信息
      xinxi() {
        this.axios.get("/api/me", {
          params: {
            guard_name: 'api'
          }
        }).then((res) => {
          console.log(res);
          if (res.code == 200) {
            // return false
            sessionStorage.setItem('userinfo', JSON.stringify(res.data))

            resetRouter()
            this.$store.commit('routestatus', 0)
            sessionStorage.removeItem('routestext')
            sessionStorage.removeItem('routes')
            sessionStorage.setItem('routes', JSON.stringify(adminroutes))

            this.$router.replace({
              path: '/index'
            });
          } else {
            eltips(res.msg, 'error')
          }
        })
      },
      // 获取基础设置
      getConfig() {
        this.axios.get("/api/user/getConfig").then((res) => {
          if (res.code == 200) sessionStorage.setItem("projectinfo", JSON.stringify(res.data))
          let link = res.data.top_link
          let $favicon = document.querySelector('link[rel="icon"]');
          if ($favicon !== null) {
            $favicon.href = link;
            $favicon.type = "image/*";
          } else {
            $favicon = document.createElement("link");
            $favicon.href = link;
            $favicon.rel = "icon";
            $favicon.type = "image/*";
            document.head.appendChild($favicon);
          }
        });
      },
    }
  };
</script>
<style>
  #adminlogin .el-input__inner {
    border: none !important;
    border-bottom: 1px solid #dcdee0 !important;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }

  #adminlogin .el-input__inner:focus {
    border-color: #3773da !important;
  }
</style>

<style scoped lang="less">
  .box {
    width: 1060px;
    height: 520px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -260px;
    margin-left: -530px;

    .boxsty {
      vertical-align: top;
      display: inline-block;
      width: 520px;
      height: 520px;
      background-color: #fff;
      margin-right: 20px;
      padding: 56px;
      border-radius: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .login {
      .title {
        text-align: center;
        margin-bottom: 30px;

        .text {
          display: inline-block;
          vertical-align: bottom;
          margin-right: 40px;
          font-size: 20px;
          color: #969799;
        }

        .active {
          font-size: 26px;
          color: #323233;
        }
      }

      .inputpad {
        padding-top: 30px;
      }

      .inputbox {
        margin-top: 30px;
        position: relative;

        .label {
          font-size: 16px;
          line-height: 48px;
          border-bottom: 1px solid #dcdee0;
          display: inline-block;
        }

        .input {
          font-size: 16px;
          width: 100%;
          height: 48px;
          line-height: 48px;
          box-sizing: border-box;
          border: none;
          border-bottom: 1px solid #dcdee0;
        }

        .codebox {
          line-height: 48px;
          position: absolute;
          right: 0;
          top: 0;
          font-size: 16px;
          color: #999;
        }

        .inputlabel {
          display: inline-block;
          width: 320px;
          float: right;
        }

        .input:focus {
          border-color: #3773da;
        }
      }

      .loginbtn {
        margin-top: 118px;
        line-height: 58px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        background: #155bd4;
        border-radius: 5px;

        &:hover {
          background: #3773da;
          cursor: pointer;
        }
      }

      .logininfo {
        overflow: hidden;
        margin-top: 24px;
        font-size: 14px;
        line-height: 14px;

        .chekbox {
          float: left;

          span {
            color: #155bd4;
          }
        }

        .changbox {
          float: right;
          font-size: 14px;

          .text {
            color: #155bd4;
            padding-right: 10px;
            line-height: 14px;
            display: inline-block;

            &:last-child {
              position: relative;
              padding-right: 0;
              padding-left: 10px;
            }

            &:last-child::after {
              content: "";
              width: 1px;
              height: 14px;
              position: absolute;
              left: 0;
              top: 0;
              background: #ebedf0;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .exp {
      position: relative;

      .title {
        font-size: 26px;
        font-weight: 500;
        color: #323233;
        text-align: center;
      }

      .text {
        line-height: 28px;
        margin: 8px 0 20px;
        font-size: 18px;
        color: #323233;
        text-align: center;
      }

      .link {
        a {
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 120px;
          margin: 0 auto;
          font-size: 14px;
          color: #155bd4;
        }
      }

      .imgbox {
        width: 408px;
        position: absolute;
        left: 56px;
        bottom: 65px;

        .img {
          text-align: center;
          width: 25%;
          display: inline-block;
          margin-top: 20px;

          img {
            width: 52px;
            height: 52px;
            box-sizing: border-box;
            display: inline-block;
            border-radius: 50%;
            box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }

  .copyright {
    position: fixed;
    left: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 14px;
  }
</style>